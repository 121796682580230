<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';
export default {
    page: {
        title: "Bilan Activités",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components:{
        Layout,PageHeader,
    },
    data() {
        return {
            title:"Bilan Activités",
            dates:{},
            display:false,
        // types:['Ordures Ménageres'],
        customDateRangeShortcuts: [
            { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
            { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
            { key: "last7Days", label: "7 derniers jours", value: 7 },
            { key: "last30Days", label: "30 derniers jours", value: 30 },
            { key: "thisMonth", label: "Ce mois", value: "month" },
            { key: "lastMonth", label: "Dernier mois", value: "-month" },
            { key: "thisYear", label: "Cette année", value: "year" },
            { key: "lastYear", label: "L'année dernière", value: "-year" },
        ],
        items: [
                {
                    text: "HSE",
                    to: {name:"service_catalog.hse"},
                },
                {
                    text: "Activités",
                    to: {name:"service_catalog.hse.trashs"},
                },
                {
                    text: "Bilan",
                    active: true,
                },
            ],
        series:[],
        chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              // categories: this.types,
              categories: [],
            },
            // yaxis: {
            //   title: {
            //     text: 'Coût Vérifié (DZD)'
            //   }
            // },
            fill: {
              opacity: 1
            },
            // tooltip: {
            //   y: {
            //     formatter: function (val) {
            //       return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD',maximumSignificantDigits: 2 }).format(val)
            //     }
            //   }
            // }
          }
        }
    },
    mounted(){
      this.activitiesList();
    },
    
    methods:{
      activitiesList(){
        this.display = false;
        this.$http.post('/ops/hse/activities/report')
        .then(res => {
          if(res.data.msg) Swal.fire('Aucune donnée',"n'a été retrouvé dans la pèriode selectionné",'warning');
          else {
            this.chartOptions.xaxis.categories = res.data.types;
            res.data.types.forEach((element,index)=>{
                this.series.push({name:element,data:[res.data.qty[index]]});
            })
            
          }this.display = true;
          
        })
      },
      getreport(){
        this.display = false;
        this.$http.post('/ops/hse/activities/report',this.dates)
        .then(res => {
          if(res.data.msg){
            Swal.fire('Aucune donnée',"n'a été retrouvé dans la pèriode selectionné",'warning');
            this.series = [];
          } 
          else {
            this.chartOptions.xaxis.categories = res.data.types;
            res.data.types.forEach((element,index)=>{
                this.series.push({name:element,data:[res.data.qty[index]]});
            })
            
          }this.display = true;
        })
      }
    }
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items"  />
    <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
            <div class="row">
                <div class="col">
                <label for="">Date</label>
                <VueCtkDateTimePicker
                    locale="fr"
                    style="display:inline-block;margin-bottom: 20px;"
                    :range="true"
                    formatted="ll"
                    color="#34495e"
                    :custom-shortcuts="customDateRangeShortcuts"
                    :only-date="true"
                    :auto-close="false"
                    v-model="dates"
                    @validate="getreport()"
                ></VueCtkDateTimePicker>
                </div>
            </div>
        </div>
    </div>
    <div id="chart" class="card p-2" v-if="display">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
    </Layout>
</template>