var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Date")]),
                _c("VueCtkDateTimePicker", {
                  staticStyle: {
                    display: "inline-block",
                    "margin-bottom": "20px"
                  },
                  attrs: {
                    locale: "fr",
                    range: true,
                    formatted: "ll",
                    color: "#34495e",
                    "custom-shortcuts": _vm.customDateRangeShortcuts,
                    "only-date": true,
                    "auto-close": false
                  },
                  on: {
                    validate: function($event) {
                      return _vm.getreport()
                    }
                  },
                  model: {
                    value: _vm.dates,
                    callback: function($$v) {
                      _vm.dates = $$v
                    },
                    expression: "dates"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm.display
        ? _c(
            "div",
            { staticClass: "card p-2", attrs: { id: "chart" } },
            [
              _c("apexchart", {
                attrs: {
                  type: "bar",
                  height: "350",
                  options: _vm.chartOptions,
                  series: _vm.series
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }